
.eCommerce-container {
    height: 100%;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.shadow-box {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, .05);
    border-radius: 10px;
    background: #fff;
}
.data-content-box {
    margin: 20px;
    padding: 20px;
    .top-bar {
        width: 218px;
        height: 50px;
        background: url("../../../../assets/images/student/customer-info.png") no-repeat;
        .text {
            padding-left: 70px;
            line-height: 50px;
            color: #fff;
            font-size: 20px;
        }
    }
}
.line-1 {
    display: flex;
    margin: 30px 0 0;
}
.data-item {
    display: flex;
    flex-direction: column;
    .eChart-content {
        height: 1%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.tab-title {
    padding-left: 15px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        width: 5px;
        height: 20px;
        background: #2DC079;
        border-radius: 3px;
    }
    .popover-title {
        font-size: 18px;
        line-height: 1;
        display: inline-block;
    }
}
.data-content {
    height: 350px;
    width: 100%;
    margin-top: 30px;
}

.sort-num {
    display: inline-block;
    background: #E7F6EF;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    line-height: 24px;
    text-align: center;
}
.sort-img {
    display: inline-block;
    img {
        display: block;
    }
}
.deal-box {
    align-items: center;
    .el-progress {
        width: 1%;
        flex: 1;
        margin-left: 15px;
        margin-right: 20px;
    }
}
.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    .no-img {
        max-width: 200px;
    }
    .text {
        color: #999;
        margin-top: 12px;
        padding: 0 20px;
    }
}
.el-table {
    ::v-deep .el-table__row.statistics-warning-row {
        background: #F9FFFC;
    }
}
.title-pause {
    span {
        &:after {
            content: '、';
        }
        &:last-child:after {
            content: none;
        }
    }
}
.pages-center {
    text-align: right;
}

.attr-list {
    margin-top: 30px;
    border: 1px solid #E5E5E5;
    .attr-line {
        display: flex;
        .line-left {
            width: 100px;
            /*min-height: 100px;*/
            text-align: center;
            background: #E7F6EF;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #E5E5E5;
        }
        .line-content {
            width: 1%;
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            padding: 10px 20px 20px 10px;
            border-bottom: 1px solid #E5E5E5;
            .tag {
                color: #2DC079;
                line-height: 1;
                margin-left: 10px;
                margin-top: 10px;
                background: #E7F6EF;
                padding: 9px 8px;
                border: 1px solid #2DC079;
            }
        }
        &:last-child {
            .line-left, .line-content {
                border-bottom: none;
            }
        }
    }
}
